export const getReason = (reason) => {
    if (reason && reason[0]){
        switch(reason[0]) {
            case 'CONTENTIEUX_OU_LR':
                return 'Un contentieux est en cours sur ce contrat.';
            case 'CONTENTIEUX':
                return 'Un contentieux est en cours sur ce contrat.';
            case 'SINISTRE_PREDECLARE':
                return 'Un sinistre pré-déclaré est présent sur ce contrat.';
            case 'PRODUIT_AUTO':
                return 'Indisponible pour les produits Auto.';
            case 'CONTRAT_RESILIE':
                return 'Contrat résilié.';
            case 'CONTRAT_SUSPENDU_DEPUIS_PLUS_DUN_AN':
                return 'Contrat suspendu depuis plus d\'un an.';
            case 'CONTRAT_EN_ETABLISSEMENT':
                return 'Ce contrat est en établissement.';
            case 'PERMISSION_DENIED':
                return 'Vous n\'avez pas la permission pour faire cette action.';
            case 'PAIEMENT_EN_ATTENTE':
                return 'Il y a déjà un paiement en attente d\'encaissement sur cette quittance.';
            case 'RECOUVREMENT':
                return 'Cette quittance est en recouvrement : merci de payer votre quittance auprès de notre partenaire iQera Services, au 0247317723.';
            case 'PRELEVEMENT':
                return 'Paiement CB non disponible.';
            case 'PRELEVEMENT_REPRESENTE':
                return 'Le prélèvement sera représenté.';
            case 'SOLDEE':
                return 'Cette quittance est déjà soldée.';
            case 'AVENANT_EN_ATTENTE':
                return 'Un avenant est en cours sur ce contrat'
            case 'PRODUIT_NVEI_ONLY':
                return 'Ce contrat n\'est pas éligible.'
            case 'DEJA_UN_SINISTRE_GARANTIE_MOBILITE':
                return 'La garantie mobilité a déjà été utilisé'
            case 'CARENCE_1_MOIS':
                return 'Vous ne pouvez pas utiliser la garantie mobilité le premier mois de souscription'
            default:
                return reason;
        }
    } else {
        return 'Le contrat doit être en cours pour être modifier'
    }
}
