import {connect} from 'react-redux'
import Accueil from './Accueil'
import {
    getBelairIdUser,
    getPermissionsUser, getPMQRemind, getRolesUser,
    getSubscribeLinksUser,
    getUserIsConcessionnaire, getUserIsConcessionnaireGP, getUserIsCourtier, getUserMustRedirectAprilOn
} from "../../../../../redux/selectors/user/user.selectors";
import {PagesRoutes} from "../PagesRoutes";
import _ from 'lodash'

const mapStateToProps = state => {

    return {
        permissions: getPermissionsUser(state),
        subscribeLinks: getSubscribeLinksUser(state),
        subscribeRoutes: _.find(PagesRoutes, ['title', 'Formulaires']).children,
        isConcessionnaire: getUserIsConcessionnaire(state),
        isCourtier: getUserIsCourtier(state),
        isConcessionnaireGP: getUserIsConcessionnaireGP(state),
        userRoles: getRolesUser(state),
        belairId: getBelairIdUser(state),
        pmqRemind: getPMQRemind(state),
        infosAprilOn: getUserMustRedirectAprilOn(state)
    }
}

const AccueilContainer = connect(mapStateToProps)(Accueil)

export default AccueilContainer
