import React from 'react';
import Card from "../../../../Commun/Card/Card";
import SouscriptionsTableContainer from "./SouscriptionsTable/SouscriptionsTableContainer";
import Poppin from "@/components/Commun/Poppin/Poppin";

const Souscriptions = (props) => {
    const { infosAprilOn, filters, isConcessionnaire, fetchQuote } = props

    return (
        <div className={'p-lg-4'}>
            {
                infosAprilOn && <>
                    <Poppin title={'PASSEZ A APRIL ON POUR CONSULTER VOS SOUSCRIPTIONS !'}>
                        <p>A partir du 3 février 2025, vos souscriptions APRIL Moto seront visibles exclusivement sur APRIL ON.</p>
                        <ul>
                            <li>Rubrique Devis & Contrats</li>
                        </ul>
                    </Poppin>
                    <div className="col-12 mb-4 d-lg-flex rounded text-white align-items-center p-2"
                         style={{background: '#629f31'}}>
                        <div className="col-12 col-lg-2 text-center">
                            <span className={'bg-white rounded-circle d-inline-block px-4'} style={{color: '#629f31',fontSize: '40px'}}>
                                !
                            </span>
                        </div>
                        <div className="col px-2">
                            <b>Consultez vos souscriptions</b><br/>
                            sur <b>APRIL ON</b>, rubrique Offres Devis & Contrats
                        </div>
                        <div className="col-12 col-lg-3 text-right">
                            <a href={'https://www.april-on.fr/contracts-and-quotes/quotes?markets=portfolio.quote.market.deux-roues'} target={'_blank'} style={{color: '#629f31'}} className={"btn btn-light"}>Consultez mes souscriptions</a>
                        </div>
                    </div>
                </>
            }
            <Card>
                <div>
                    <h3 className={'m-0 pb-3 border-bottom border-dark'}>
                        <span className={'text-color-secondary'}>Vos souscriptions</span> de moins de 30 jours
                    </h3>

                    {
                        !isConcessionnaire && <div className="row mt-5">
                            <div className="col-lg-6 col-md-12">
                                <label>Type d'affaires</label>
                                <select className="form-control" onChange={(e) => fetchQuote({filters: {...filters, acteGestion: e.target.value}})}>
                                    <option value={''}>Tous type d'affaires</option>
                                    <option value={'AN'}>Affaires nouvelles</option>
                                    <option value={'AV'}>Avenants</option>
                                </select>
                            </div>
                        </div>
                    }

                    <SouscriptionsTableContainer />

                </div>
            </Card>
        </div>
    );
};

export default Souscriptions;
